<template>
  <div class="page-content py-5 pt-0">
    <div class="text-center mt-5">
      <i class="fas fa-hourglass-half display-1 edc-color-primary"></i>
      <h3 class="mt-5 display-5">قريباً</h3>
      <router-link
        :to="{ path: '/products/edc-waqf/' }"
        class="btn edc-btn-secondary text-white rounded-pill mt-3"
      >
        {{ $t("btn.donate_now") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import ogImage from "@/assets/images/slider/waqf-project.png";
export default {
  name: "Wafq",
  metaInfo() {
    return {
      titleTemplate: this.$t("title.edc_waqf") + " | %s",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("title.edc_waqf"),
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:url", property: "og:url", content: window.location.href },
        {
          vmid: "og:image",
          property: "og:image",
          content: window.location.origin + ogImage || "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>