import { render, staticRenderFns } from "./Waqf.vue?vue&type=template&id=234b1258&scoped=true&"
import script from "./Waqf.vue?vue&type=script&lang=js&"
export * from "./Waqf.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234b1258",
  null
  
)

export default component.exports